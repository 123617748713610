import React from "react";

import Logo from "../../assets/icons/logo.svg";

const HeaderSmall = (props) => {
  const { title } = props;
  return (
    <div className="HeaderSmall">
      <img src={Logo} alt="logo" />
      <h2>{title}</h2>
    </div>
  );
};

export default HeaderSmall;
