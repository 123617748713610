import React from "react";

import "../../App.css";
import Logo from "../../assets/icons/logo.svg";
import Footer from "../../components/Footer";
import Extensions from "../../components/Extensions";
import UninstalledForm from "../../components/UninstalledForm";

const Uninstalled = (props) => {
  return (
    <div className="Subpage">
      <div className="Header Header__Uninstall">
        <div className="Header__Container">
          <img src={Logo} alt="logo" className="xs" />
          <h2>We are sorry to see you leaving.</h2>
          <p>You can always reinstall the extension if this was a mistake.</p>
          <a
            href="https://chrome.google.com/webstore/detail/ciddleogiondgeldkddeknbdjhjimbld"
            className="Store-Button"
          >
            Download Wink Coupons
          </a>
        </div>
      </div>
      <div className="Uninstall__Middle">
        <UninstalledForm />
      </div>

      <div className="Uninstall__Content">
        <Extensions />
      </div>
      <Footer />
    </div>
  );
};

export default Uninstalled;
