import React from "react";

import ExtensionItem from "./ExtensionItem";
import MNTImg from "../../assets/images/webstore-1.png";
import ZMImg from "../../assets/images/webstore-2.png";
import SBNTEImg from "../../assets/images/sbnte.jpg";

const Extensions = (props) => {
  const { displayFour } = props;
  return (
    <div className="Extensions">
      {displayFour && <h3>More Extensions</h3>}
      <div className="Container">
        <div className="Extensions__Column">
          <div className="Extensions__Intro">
            <h4>More extensions</h4>
            <h6>To make your life better</h6>
            <div className="Extensions__Intro__Divider" />
          </div>
        </div>
        <div className="Extensions__Column">
          <ExtensionItem
            title="Start A Better New Tab"
            link="http://www.start-a-better-new-tab.com/"
            img={SBNTEImg}
          >
            Hands down, the best home page for Chrome. Beautiful wallpapers,
            amazing features, blazingly fast New Tab.
          </ExtensionItem>
        </div>
        <div className="Extensions__Column">
          <ExtensionItem
            title="Zen Mind"
            link="https://www.zenmindnewtab.com/"
            img={ZMImg}
          >
            Meditate, relax and fight stress with our new tab Chrome extension!
          </ExtensionItem>
        </div>
        <div className="Extensions__Column">
          <ExtensionItem
            title="Movie News Tab Extension"
            link="https://www.movienewstab.com/"
            img={MNTImg}
          >
            See the latest news, videos, comments and teasers related to
            upcoming and popular movies.
          </ExtensionItem>
        </div>
      </div>
    </div>
  );
};

export default Extensions;
