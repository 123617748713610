import React from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_BASE_PATH } from "../../constants/api";

const ContactForm = (props) => {
  let history = useHistory();
  return (
    <div className="ContactForm">
      <Formik
        initialValues={{ name: "", email: "", message: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Email is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.name) {
            errors.name = "Name is required";
          }
          if (!values.message) {
            errors.message = "Message is required";
          }
          return errors;
        }}
        onSubmit={async (values) => {
          const { name, email, message } = values;
          try {
            await axios.post(`${API_BASE_PATH}/contact`, {
              message: message.trim(),
              name,
              email,
              sourceType: "Wink Coupons Web Site",
              url: window.location.href,
            });
            history.push("/submitted");
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="ReviewInput__Label">
              Name{" "}
              {errors.name && touched.name && (
                <span className="ReviewInput__Error">* {errors.name}</span>
              )}
            </div>

            <input
              className="ReviewInput textfield"
              placeholder="Enter your name"
              value={values.name}
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className="ReviewInput__Label">
              E-Mail{" "}
              {errors.email && touched.email && (
                <span className="ReviewInput__Error">* {errors.email}</span>
              )}
            </div>

            <input
              className="ReviewInput textfield"
              placeholder="Enter your email"
              value={values.email}
              type="text"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className="ReviewInput__Label">
              Message{" "}
              {errors.message && touched.message && (
                <span className="ReviewInput__Error">* {errors.message}</span>
              )}
            </div>
            <textarea
              className="ReviewInput message"
              placeholder="Enter your message "
              value={values.message}
              name="message"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <button className="UninstalledForm__Button" type="submit">
              Send Message
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
