import React from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import axios from "axios";

import Checkbox from "../Checkbox";
import { API_BASE_PATH } from "../../constants/api";

const UninstalledForm = (props) => {
  let history = useHistory();
  return (
    <div className="UninstalledForm">
      <h5>
        Why did you uninstall WinkCoupons?
        <br />
        Please check all that apply:
      </h5>
      <Formik
        initialValues={{ reasons: [], message: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.reasons.length) {
            errors.reasons = "Uninstall reason required.";
          }
          if (!values.message.trim().length) {
            errors.message = "Message required.";
          } else if (values.message.trim().length < 5) {
            errors.message = "Message must be at least 5 characters long.";
          }
          return errors;
        }}
        onSubmit={async (values) => {
          const { reasons, message } = values;
          try {
            await axios.post(`${API_BASE_PATH}/feedback`, {
              feedback: message.trim(),
              reason: reasons.join(","),
              sourceType: "Wink Coupons Web Site",
            });
            history.push("/submitted");
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form className="UninstalledForm__Form" onSubmit={handleSubmit}>
            <div className="UninstalledForm__Checkboxes">
              <Checkbox name="reasons" value="hasBugs" title="Bugs" />
              <Checkbox
                name="reasons"
                value="tooManyAdsOrSpam"
                title="Ads / Spam"
              />
              <Checkbox
                name="reasons"
                value="privacyConcerns"
                title="Privacy Concerns"
              />
              <Checkbox
                name="reasons"
                value="worksUnexpectedly"
                title="Extension doesn't work as expected"
              />
            </div>
            {!!errors.reasons && (
              <div className="ReviewInput__Error">* {errors.reasons}</div>
            )}

            <textarea
              className="ReviewInput textarea"
              placeholder="Other"
              value={values.message}
              name="message"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {!!errors.message && (
              <div className="ReviewInput__Error">* {errors.message}</div>
            )}
            <button className="UninstalledForm__Button" type="submit">
              Send Feedback
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UninstalledForm;
