import React from "react";

import "../../App.css";
import Logo from "../../assets/icons/logo.svg";
import Extensions from "../../components/Extensions";
import Footer from "../../components/Footer";
import Stores from "../../components/Stores";

const Installed = (props) => {
  return (
    <div className="Subpage">
      <div className="Header installed" style={{ minHeight: "auto" }}>
        <div className="Header__Container">
          <img src={Logo} alt="logo" />
          <h3>Thank you for installing Wink Coupons!</h3>
          <p>The best place for coupons and vouchers on the Chrome WebStore.</p>
          {/* <a
            href="https://chrome.google.com/webstore/detail/ciddleogiondgeldkddeknbdjhjimbld/reviews"
            className="Store-Button Rating"
          >
            Rate
          </a> */}
        </div>
      </div>
      <div className="Content_w">
        <div className="Container s">
          <Stores />
        </div>
        <Extensions />
        <Footer />
      </div>
    </div>
  );
};

export default Installed;
