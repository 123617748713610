import React from "react";

import "../../App.css";
import Logo from "../../assets/icons/logo.svg";
import Extensions from "../../components/Extensions";
import Footer from "../../components/Footer";

const Submitted = (props) => {
  return (
    <div className="Subpage">
      <div className="Header">
        <div className="Header__Container">
          <img src={Logo} alt="logo" />
          <h3>Submitted!</h3>
          <p>
            Thank you for your feedback, we will do our best too meet your
            needs.
          </p>
        </div>
        <Extensions />
      </div>
      <Footer />
    </div>
  );
};

export default Submitted;
