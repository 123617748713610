import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="Footer-Sub">
      <Link className="Footer-Sub__Link" to="/">
        Home
      </Link>
      <Link className="Footer-Sub__Link" to="/contact">
        Contact Us
      </Link>
      <Link className="Footer-Sub__Link" to="/privacy">
        Privacy Policy
      </Link>
    </div>
  );
};

export default Footer;
