import React from "react";
import { Link } from "react-router-dom";

import HeaderSmall from "../../components/HeaderSmall";
import Footer from "../../components/Footer";
import ContactForm from "../../components/ContactForm";

const Contact = (props) => {
  return (
    <div>
      <div className="Privacy">
        <HeaderSmall title="Contact Us" />
        <div className="Privacy__Container">
          <Link to="/" className="Privacy__Backlink">
            &lt; Back to Homepage
          </Link>
          <h2>Contact Us</h2>
          <ContactForm />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
