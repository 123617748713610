import React from "react";
import "./style.css";

import Footer from "../../components/Footer";
import Preview1 from "../../assets/images/preview-1.png";
import Preview2 from "../../assets/images/preview-2.png";
import Preview3 from "../../assets/images/preview-3.png";
import Preview4 from "../../assets/images/preview-4.png";
import Logo from "../../assets/icons/logo.svg";
import Testimonials from "../../components/Testimonials";
import Stores from "../../components/Stores";

const Home = (props) => {
  return (
    <div>
      <div className="Header">
        <div className="Container m">
          <div className="Header--Text">
            <a href="/">
              <img className="Header--Logo" src={Logo} alt="logo" />
            </a>
            <h1 className="Header--Intro">
              Make the best out of your shopping experience and save valuable
              money using Wink Coupons!
            </h1>
            <a
              href="https://chrome.google.com/webstore/detail/ciddleogiondgeldkddeknbdjhjimbld"
              className="Store-Button"
            >
              Download Chrome Extension
            </a>
          </div>
          <div className="Header--Image">
            <img src={Preview1} alt="inuse" />
          </div>
        </div>
      </div>
      <div className="Content">
        <div className="Container s">
          <Stores />
          <div className="Content--Section one">
            <div className="Content--Image">
              <img src={Preview2} alt="inuse" />
            </div>
            <p className="Content--Text">
              <strong>How does it work?</strong>
              <br />
              <p>
                After installing Wink Coupons from the Chrome WebStore you can
                continue browsing. Whenever you are on a site where coupons or
                discounts are available, Wink Coupons will notify you!
              </p>
            </p>
          </div>
        </div>
        <div className="Container l">
          <div className="Content--Section two">
            <p className="Content--Text s align-r">
              <p>
                With a single click Wink Coupons will apply any available
                discount for your desired products.
              </p>
            </p>
            <div className="Content--Image l">
              <img src={Preview3} alt="inuse" />
            </div>
          </div>
        </div>
        <Testimonials />
        <div className="Container s">
          <div className="Content--Section three">
            <div className="Content--Image">
              <img src={Preview4} alt="inuse" />
            </div>
            <p className="Content--Text">
              <p>
                Wink Coupons is connected to a huge database willed with coupons
                and vouchers for the biggest shops worldwide. We are updating
                our database on a daily basis so you'll never miss out a chance
                to save money!
              </p>
            </p>
          </div>
        </div>
      </div>
      <div className="Footer">
        <div className="Container s align-c">
          <img className="Header--Logo" src={Logo} alt="logo" />
          <p className="Footer--Intro">
            Saving money has never been easier. Thank you for using Wink
            Coupons!
          </p>
          <a
            href="https://chrome.google.com/webstore/detail/ciddleogiondgeldkddeknbdjhjimbld"
            className="Store-Button"
          >
            Download Chrome Extension
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
