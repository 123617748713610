import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import Home from "./pages/Home";
import Installed from "./pages/Installed";
import Uninstalled from "./pages/Uninstalled";
import Privacy from "./pages/Privacy";
import Contact from "./pages/Contact";
import Submitted from "./pages/Submitted";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <>
      {process.env.REACT_APP_ENABLE_ANALYTICS === "true" && (
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_UA_ID}`}
          />
          <script>
            {`window.dataLayer = window.dataLayer || [];
                          function gtag()
  
                          {dataLayer.push(arguments);}
                          gtag('js', new Date());
  
                          gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_UA_ID}')`}
          </script>
          {/* <script>
          {`    (function(h,o,t,j,a,r){
                      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                      h._hjSettings={hjid:${process.env.REACT_APP_HOTJAR_ID},hjsv:${process.env.REACT_APP_HOTJAR_SV}};
                      a=o.getElementsByTagName('head')[0];
                      r=o.createElement('script');r.async=1;
                      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                      a.appendChild(r);
                      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script> */}
        </Helmet>
      )}
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/uninstalled">
              <Uninstalled />
            </Route>
            <Route path="/installed">
              <Installed />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/submitted">
              <Submitted />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
