import React from "react";
import Testimonial from "./Testimonial";

const Testimonials = (props) => {
  return (
    <div className="Testimonials">
      <div className="Container s">
        <Testimonial name="Sarah" age={28} location="Michigan">
          I was shocked after i discovered the amount of money i can save using
          wink coupons.
          <br />
          Simple and effective, can recommend it for everyone
        </Testimonial>
        <Testimonial name="Hugo" age={25} location="Rio De Janeiro">
          I am always watching out for the best deals for my favorite products
          and with Wink Coupons it's so easy to accomplish exactly that
        </Testimonial>

        <Testimonial name="Martin" age={30} location="Cologne">
          It always bothered me to check different price comparison websites to
          find the best price,now i have it all with just one extension
        </Testimonial>
        <Testimonial name="Lisa" age={52} location="London">
          I am not experienced with applying discounts online but with Wink
          Coupons even I can save money while shopping online.
        </Testimonial>
        <div className="align-c">
          <a
            href="https://chrome.google.com/webstore/detail/ciddleogiondgeldkddeknbdjhjimbld"
            className="Store-Button"
          >
            Download Chrome Extension
          </a>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
