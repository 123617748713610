import React from "react";
const Testimonial = (props) => {
  const { name, age, location, children } = props;
  return (
    <div className="Testimonial">
      <div className="Testimonial__Icon">{name.substr(0, 1)}</div>
      <div className="Testimonial__Content">
        <h4>
          {name}, {age} from {location}
        </h4>
        <p>{children}</p>
      </div>
    </div>
  );
};

export default Testimonial;
