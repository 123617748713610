import React from "react";

const ExtensionItem = (props) => {
  const { img, link, title, children } = props;
  return (
    <div className="ExtensionItem">
      <a
        className="ExtensionItem__Img"
        href={link}
        style={{ backgroundImage: `url(${img})` }}
      ></a>
      <div className="ExtensionItem__Content hide-mobile">
        <h5>{title}</h5>
        <p>{children}</p>
        <a className="ExtensionItem__Link" href={link}>
          View more
        </a>
      </div>
    </div>
  );
};

export default ExtensionItem;
