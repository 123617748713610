import React from "react";

import AliExpressImg from "../../assets/images/stores/aliexpress-c.png";
import BestBuyImg from "../../assets/images/stores/bestbuy-c.png";
import EbayImg from "../../assets/images/stores/ebay-c.png";
import HelloFreshImg from "../../assets/images/stores/hellofresh-c.png";
import Hilton from "../../assets/images/stores/hilton-c.png";
import QvcImg from "../../assets/images/stores/qvc-c.png";
import TicketmasterImg from "../../assets/images/stores/ticketmaster-c.png";
import UdemyImg from "../../assets/images/stores/udemy-c.png";

const Stores = (props) => {
  return (
    <div className="StoresOuter">
      <h2>We support 400+ stores, including</h2>
      <div className="StoresInner">
        <img src={AliExpressImg} alt="store-logo" />
        <img src={BestBuyImg} alt="store-logo" />
        <img src={EbayImg} alt="store-logo" />
        <img src={HelloFreshImg} alt="store-logo" />
        <img src={Hilton} alt="store-logo" />
        <img src={QvcImg} alt="store-logo" />
        <img src={TicketmasterImg} alt="store-logo" />
        <img src={UdemyImg} alt="store-logo" />
      </div>
    </div>
  );
};

export default Stores;
